import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import { getBlockContent, getBlockImage } from "../utils/helpers";
// Containers
import RenderRichContent from "../components/containers/RenderRichContent";

const ConfirmationWaitList = ({ data }) => {
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  return (
    <>
      <Layout metaInfo={metaStructure}>
        <RenderRichContent content={_rawContent} />
      </Layout>
    </>
  );
};

export default ConfirmationWaitList;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "confirmation-wait-list" } }) {
      _rawContent(resolveReferences: { maxDepth: 10 })
      pageTitle
      shoutOut
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
